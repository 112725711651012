import dayjs from 'dayjs';
import dayLocaleData from 'dayjs/plugin/localeData';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(dayLocaleData);

/* ROUTERS  */
export const ROUTES = {
  MAIN: '/',
  LOGOUT: '/logout',
  SIGNUP: '/signup',
  LOGIN: '/login',
  FORGET_PASSWORD: '/forgot-password',
  RESET: '/reset-password',
  VERIFY_USER: '/verify-user',
  AUTHENTICATION: '/authentication',
  PROFILE: '/profile',
  WORKSPACE: '/workspace',
  AUTH_DETAILS: '/auth-details',
  WEBHOOKS: '/webhooks',
  BOOKING: '/booking',
};

/*  Modules */
export const MODULES = {
  DASHBOARD: 'Dashboard',
};

export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const USER = 'USER';

export const CURRENT_EMAIL = 'CURRENT_EMAIL';
export const IS_NEW_USER = 'IS_NEW_USER';
export const WORKSPACE_KEY = 'WORKSPACE_KEY';
export const REDIRECT_WAITING = 'REDIRECT_WAITING';

/* OTP */
export const OTP_SIZE = 6;
export const OTP_ATTEMPT = 3;

export const ERROR_PAGE_TITLE = 'Oops! An error occurred!';
export const ERROR_PAGE_SUBTITLE =
  'Something is broken. Please let us know what you were doing when this error occurred. We will fix it as soon as possible. Sorry for any inconvenience caused.';

export const REGEX = {
  NAME: /^[a-z ,.'-]+$/i,
  ZIPCODE: /^[0-9]{5,6}$/,
  CITY: /^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/,
  WEB_URL:
    /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  PASSWORD: /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9!@#$%^&*]{8,16}$/,
  PHONE: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/,
  EMAIL:
    /^(([^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+(\.[^<>()[\]\\.,;:!#$*%^'`~={}?/&\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  AMOUNT: /^\d+$|^\d+\.\d*$/,
  OPTIONALNEGATIVEAMOUNT: /^[-]?\d+$|^[-]?\d+\.\d*$/,
  NUMBER: /^\d+$/,
  COUNTRY_CODE_PHONE: /^[0-9]{7,12}$/,
};

export const ALL_IMAGE_UPLOAD = ['jpeg', 'jpg', 'png'];

export const BREAKPOINTS = {
  mobile: 550,
  tablet: 767,
  desktop: 1000,
  hd: 1200,
};

export const GUTTER_VARIATIONS = { xs: 16, sm: 16, md: 24, lg: 32 };

export const COMPANY_INFO = {
  COOKIE_EXPIRY: 259200,
  FOOTER_TEXT: `Calendar Scheduling © ${new Date().getFullYear()}`,
};

export const WORKSPACE_MODULE = {
  AUTH_DETAILS: { key: 'auth-details', label: 'Auth Details' },
  WEBHOOKS: { key: 'webhooks', label: 'Webhooks' },
};

export const TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
export const HOUR_TIME_FORMAT = 'hh:mm A';
export const MONTH_DATE_FORMAT = 'MMM DD';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const WEEKDAYS = ['', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const CALL_TYPE = {
  WEBINAR: 'WEBINAR',
  ONE_TO_ONE: 'ONE_TO_ONE',
};

// Get the current timezone
const currentTimezone = dayjs.tz.guess();

// Get the timezone offset in hours
export const DEFAULT_OFFSET = dayjs().tz(currentTimezone).utcOffset() / 60;
